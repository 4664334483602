.App {
  text-align: center;
}

.btn {
  background-color: #e1c76b!important;
  color: black!important;
  border: none!important;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.topDiv {
  display: flex;
  justify-content: space-between;
  height: 45px;
  padding-top: 10px;
  background-color: #434651;
  color: white;
  position: fixed;
  z-index: 999;
  width: 100%;
  margin-bottom: 40px;
  
}


.closeDiv {
  text-align: right;
  padding-right: 20px;
}

.leaflet-top {
  margin-top: 40px;
  
}

.linkDiv {
  margin-top: 60px;
 
}

.grid-top {
  padding-top: 60px;
}

.downloadDiv {
  text-align: left;
  padding-top: 30px;
  padding-left: 20px;
}

.backDiv {
  padding-right: 10px;
}

.titleDiv {
  margin-left: auto;
  margin-right: auto;
}

.hamburger {
  margin-top: 0;
  margin-left: 10px;
}

.D {
  color:navy;
}

.R {
  color:red;
}



.sidebar {
  
  width: 350px;
  height: 100vh;
  background-color: darkslategrey;
  color:white;
  position: fixed;
  top:40px;
  left: 0;
  transition: 850ms;
  padding-left: 20px;
  padding-top: 20px;
  z-index: 999;
}

.no-sidebar {
  width: 350px;
  height: 100vh;
  background-color: darkslategrey;
 left: -100%;
 
  position: fixed;
  top:40px;
  
  transition: 850ms;
  padding-left: 20px;
  padding-top: 20px;
  z-index: 999;
}

.colorBlue {
  color: red;
}